@import '~/styles/responsive.scss';

.product_item_hover {
    position: relative;

    .front_img,
    .back_img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity 0.4s cubic-bezier(0.23, 1, 0.32, 1), transform 2.5s cubic-bezier(0.23, 1, 0.32, 1);

        // img {
        //     transform: scale(1.025);
        // }
    }

    .front_img {
        opacity: 1;
        z-index: 1;
    }

    .back_img {
        opacity: 0;
        transform: scale(1);
    }

    &:hover {
        @include screen(lg) {
            .front_img {
                opacity: 0;
            }

            .back_img {
                opacity: 1;
                transform: scale(1.05);
            }
        }
    }
}

.product_item {
    // display: flex;
    // flex-direction: column;
    // gap: 0.5rem;
    // &:hover {
    //     box-shadow: 0px 4px 15px 0 var(--grey-100);
    // }
    
    .from,
    .price {
        @include screen(xs) {
            font-size: 1rem !important;
        }
    }

    @include screen(lg) {
        &:hover {
            .add_to_cart {
                transform: translateY(0);
            }
        }
    }

    .add_to_cart {
        position: absolute;
        z-index: 2;
        background-color: var(--product-item-add-to-cart-bg);
        width: 100%;
        height: 3.5rem;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--product-item-add-to-cart-color);
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.125rem;
        transition: var(--transition);
        transform: translateY(100%);

        &.medium,
        &.small {
            font-size: 1rem;
            height: 2.5rem;
        }
    }

    .favourite {
        position: absolute;
        z-index: 2;
        right: 0;

        @include screen(md) {
            padding: 1.25rem;
        }

        &.medium,
        &.small {
            padding: 0.5rem;
        }
    }

    .tags {
        position: absolute;
        z-index: 2;
        display: flex;
        color: white;

        &.separated {
            display: flex;
            gap: 0.25rem;
            padding: 0.75rem;

            .tag_item {
                border-radius: 0.5rem;
            }
        }
        .tag_item {
            padding: 0.125rem;
            text-transform: capitalize;
            background-color: var(--primary);

            &.hot {
                background-color: var(--hot-badge-bg);
            }

            &.new {
                background-color: var(--new-badge-bg);
            }
        }
    }
}

.menu_item_wrapper {
    &:hover {
        .menu_dropdown {
            display: block;
            z-index: 1;
        }
    }
}

.menu_item {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 0.5rem;
    transition: all 0.25s ease-in-out;
    height: 3rem;
    color: var(--navigation-color);
    text-transform: var(--navigation-menu-item-text-transform);

    &:hover {
        background-color: var(--navigation-menu-item-hover-bg);
        color: var(--navigation-menu-item-hover-color);
    }

    .badge {
        background-color: var(--navigation-menu-item-badge-bg);
        color: var(--navigation-menu-item-badge-color);
        font-size: 0.875rem;
        padding: 0.25rem 0.75rem;
        border-radius: 1rem;
    }

    &:hover::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 30px;
        left: 0;
        bottom: -20px;
        background: transparent;
        z-index: 1;
        cursor: pointer;
    }
}

.menu_dropdown {
    display: none;
    position: absolute;
    // min-width: 100%;
    // height: 570px;
    // width: 100%;
    height: auto;
    border: 1px solid var(--grey-100);
    box-shadow: 0px 4px 15px 0 var(--grey-100);
    top: 100%;
    background-color: white;
    // overflow-y: scroll;
    // overflow-x:scroll ;

    &.fullWidth {
        width: 100%;
        max-height: 570px;
        padding: 2rem 3rem 3rem;
        overflow-y: auto;
    }
    a {
        &:hover {
            color: var(--primary);
        }
    }
    // * {
    //   color: unset !important;
    // }
}

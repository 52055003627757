@import '~/styles/responsive.scss';

.product_item_hover {
    position: relative;

    .front_img,
    .back_img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity 0.4s cubic-bezier(0.23, 1, 0.32, 1), transform 2.5s cubic-bezier(0.23, 1, 0.32, 1);
    }

    .front_img {
        opacity: 1;
        z-index: 1;
    }

    .back_img {
        opacity: 0;
        transform: scale(1);
    }

}

.product_item {
  
    .title{
        line-height: 1.3;
    }
    .discount{
        color: var(--primary) !important;
    }
    .price{
        color: var(--primary);
        font-weight: 700;
        
    }

 
    .favourite {
        position: absolute;
        z-index: 2;
        right: 0;

        @include screen(md) {
            padding: 1.25rem;
        }

        &.medium,
        &.small {
            padding: 0.5rem;
        }
    }

    .tags {
        position: absolute;
        z-index: 2;
        display: flex;
        color: white;

        &.separated {
            display: flex;
            gap: 0.25rem;
            padding: 0.75rem;

            .tag_item {
                border-radius: 0.5rem;
            }
        }
        .tag_item {
            padding: 0.125rem;
            text-transform: capitalize;
            background-color: var(--primary);

            &.hot {
                background-color: var(--hot-badge-bg);
            }

            &.new {
                background-color: var(--new-badge-bg);
            }
        }
    }
    .remove_icon {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        cursor: pointer;
        &.separated {
          padding: 0.75rem;
        }
      }
}
@include screen(lg) {
    .product_item_hover:hover {
        .front_img {
            opacity: 0;
        }

        .back_img {
            opacity: 1;
            transform: scale(1.05);
        }
    }
}
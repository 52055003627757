.badge {
    background-color: var(--navigation-menu-item-badge-bg);
    color: var(--navigation-menu-item-badge-color);
    font-size: 0.875rem;
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
  }

.menu_item{
  background-color: #fff;
  position: relative;
  &:hover{
    // color: var(--navigation-menu-item-badge-color);
    .menu_item_child{
      display: block;
    }
  }
}

.menu_item_child{
  position: absolute;
  display: none;
  left: 100%;
  width: 100%;
  top: 0;
  // margin-left: 1rem;
  height: auto;
  background-color: #fff;
  border: 1px solid var(--grey-100);;
  box-shadow: 10px 4px 15px 0 var(--grey-100);

}
  